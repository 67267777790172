<template>
  <div>
    <div style="text-align: right;z-index: 2;">
      <el-button size="small" style="text-align: right;" v-if="($store.state.Login.Department == 'IPQC' || $store.state.Login.Jurisdiction == 0) && !isendbool"  type="warning" @click="DekERPwlandget()">{{$t('All.重新获取ERP物料')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="($store.state.Login.Department == 'IPQC' || $store.state.Login.Jurisdiction == 0) && !isendbool"  type="primary" @click="TabComponent = 'LQrcode'">{{$t('All.扫描物料')}}</el-button>
    </div>
    <div class="zhadminadd" id="printTest">
    <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
    <h3 style="text-align: center;">{{$t('All.生产使用物料')}}</h3>
    <h4 style="text-align: center;">{{ $store.state.Gongdan }}</h4>
    <div style=" padding: 10px; text-align: center;">
      <el-table :data="Material" border style="min-width: 1024px;">
        <el-table-column  type="index" width="50">  </el-table-column>
        <el-table-column  prop="Item_no" sortable  :label="$t('All.料号')">  </el-table-column>
        <el-table-column  prop="Material_name" sortable  :label="$t('All.品名')">  </el-table-column>
        <el-table-column  prop="Specifications" sortable  :label="$t('All.规格')">  </el-table-column>
        <el-table-column  prop="Supplier" sortable  :label="$t('All.供应商')">  </el-table-column>
        <el-table-column  prop="Feeding_date" sortable  :label="$t('All.进料日期')">  </el-table-column>
        <el-table-column  prop="Person" sortable  :label="负责人">  </el-table-column>
        <el-table-column  prop="Quantity" sortable  :label="$t('All.数量')" width="80">  </el-table-column>
        <el-table-column  prop="TQuantity" sortable  :label="$t('All.已退')" width="80">  </el-table-column>
        <el-table-column  prop="Lot_no"  label="LOT NO">  </el-table-column>
        <el-table-column  prop="Username" sortable :label="$t('All.扫描者')" >  </el-table-column>
        <el-table-column fixed="right"  :label="$t('All.操作')" width="150" v-if="!isendbool">
          <template slot-scope="scope">
            <el-button v-if="scope.row.State == 0 && ($store.state.Login.Department == 'IPQC' || $store.state.Login.Jurisdiction == 0)" @click="Delwuliao(scope.row)" type="danger" size="mini">{{$t('All.删除')}}</el-button>
            <el-button v-if="scope.row.State == 0 && ($store.state.Login.Jurisdiction == 9 || $store.state.Login.Jurisdiction == 0)" @click="Tuiliao(scope.row)" type="warning" size="mini">{{$t('All.退料')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center;" v-if="isendbool">
      <h3 style="text-align: center;">{{$t('All.ERP物料与生产使用物料对比')}}</h3>
      <el-table
        :data="ERPMaterial"
        style="width: 100%;padding: 10px; text-align: center;">
        <el-table-column prop="Wmcpil" :label="$t('All.料号')"></el-table-column>
        <el-table-column
          prop="Wmdsc1"
          :label="$t('All.品名')"
          >
        </el-table-column>
        <el-table-column
          prop="Wmdsc2"
          :label="$t('All.规格')"
          >
        </el-table-column>
        <el-table-column
          prop="Wmuorg"
          :label="$t('All.标准量')">
        </el-table-column>
        <el-table-column
          prop="Wmtrqt"
          :label="$t('All.已发量')">
        </el-table-column>
        <el-table-column
          prop="Sjshiyongl"
          :label="$t('All.实际使用量')">
          <template slot-scope="scope">
            {{scope.row.Wmum === 'GM' ? scope.row.Sjshiyongl * 1000 : scope.row.Sjshiyongl}}
          </template>
        </el-table-column>
        <el-table-column prop="Dosageratio" sortable
          :label="$t('All.用量比例')">
          <template slot-scope="scope">
            {{scope.row.Dosageratio}}%
          </template>
        </el-table-column>
        <el-table-column prop="Standardamount" sortable
          :label="$t('All.标准金额')">
          <template slot-scope="scope">
            {{scope.row.Standardamount}}
          </template>
        </el-table-column>
        <el-table-column prop="Actualamount" sortable
          :label="$t('All.实际金额')">
          <template slot-scope="scope">
            {{scope.row.Actualamount}}
          </template>
        </el-table-column>
        <el-table-column prop="Amountratio" sortable
          ：label="$t('All.金额比例')">
          <template slot-scope="scope">
            {{scope.row.Amountratio}}%
          </template>
        </el-table-column>
        <el-table-column
          prop="Wmum"
          :label="$t('All.单位')">
        </el-table-column>
      </el-table>
    </div>
    </div>
  </div>
  <transition name="fade-transform" mode="out-in">
        <component :is="TabComponent" :Iscoder="Iscoder" :Getxwuliao="Getxwuliao" :Materialadddata="Materialadddata" :gongdan="$store.state.Gongdan" :MaterialData="MaterialData" :GetMaterial="GetMaterial" style="z-index: 888;"></component>
    </transition>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Material: [{
        Numbers: '',
        Index: 0,
        Quantity: '',
        IQCNumbers: '',
        Material_name: '',
        Supplier: '',
        Feeding_date: '',
        Person: '',
        Lot_no: '',
        Name: '',
        Serialcode: ''
      }],
      TabComponent: '',
      MaterialData: {},
      ERPMaterial: [],
      isendbool: false
    }
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  components: {
    LQrcode: () => import('./LQrcode'),
    Tuliao: () => import('./Tuliao')
  },
  created () {
    this.GetMaterial(this.$store.state.Gongdan)
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['FQCNumerb']),
    Iscoder (row) {
      this.TabComponent = row
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    getCurrentTime () {
      // 获取当前时间并打印
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const gettime = yy + '-' + mm + '-' + dd
      return gettime
    },
    Tuiliao (row) {
      this.MaterialData = row
      this.TabComponent = 'Tuliao'
    },
    Delwuliao (row) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          this.removeMaterial(row)
        })
        .catch(() => {
        })
    },
    Materialaddlist (item, index) {
      const contr = {
        Numbers: item.Numbers,
        Index: item.Index + 1,
        Quantity: '',
        IQCNumbers: '',
        Material_name: '',
        Supplier: '',
        Feeding_date: '',
        Person: '',
        Lot_no: '',
        Name: '',
        Serialcode: ''
      }
      this.Material.splice(index + 1, 0, contr)
      for (let i = 0; i < this.Material.length; i++) {
        this.Material[i].Index = i
      }
    },
    async removeMaterial (item) {
      const { data: res } = await this.$http.post('/api/Semifinished/DelMaterial', item)
      if (res.status !== 200) return this.$message.error(res.msg)
      var index = this.Material.indexOf(item)
      if (index !== -1) {
        this.Material = this.Material.filter(x => x.Serialcode !== item.Serialcode)
      }
      for (let i = 0; i < this.Material.length; i++) {
        this.Material[i].Index = i
      }
    },
    Getxwuliao (row) {
      for (let j = 0; j < this.Material.length; j++) {
        if (this.Material[j].Serialcode === row) {
          this.$message.error(this.$t('All.此PASS数据已被采集'), this.$t('All.请勿重复扫描'))
          return true
        }
      }
      return false
    },
    Materialadddata (row) {
      /* for (let j = 0; j < this.Material.length; j++) {
        if (this.Material[j].Serialcode === row.Serialcode) {
          this.$message.error('此PASS数据已被采集，请勿重复扫描！')
          return 'NO'
        }
      }
      var res = this.$http.post('/api/Semifinished/AddMaterial', row)
      console.log(res)
      if (res.status !== 200) return this.$message.error(res.msg)
      row.Index = this.Material.length
      this.Material.push(row)
      this.$message.success('采集成功！')
      return 'OK' */
      for (let j = 0; j < this.Material.length; j++) {
        if (this.Material[j].Serialcode === row.Serialcode) {
          this.$message.error(this.$t('All.此PASS数据已被采集'), this.$t('All.请勿重复扫描'))
          return
        }
      }

      // 发起异步请求
      this.$http.post('/api/Semifinished/AddMaterial', row)
        .then((response) => {
          // 检查HTTP响应状态码
          if (response.status === 200) {
            const res = response.data

            // 处理服务器端返回的数据
            if (res.status === 200) {
              row.Index = this.Material.length
              this.Material.push(row)
              this.$message.success(this.$t('All.采集成功'))
            } else {
              this.$message.error(res.msg)
            }
          } else {
            this.$message.error(this.$t('All.HTTP请求失败'))
          }
        })
        .catch((error) => {
          console.error(error)
          this.$message.error(this.$t('All.操作失败'))
        })
    },
    async GetMaterial (row) {
      const { data: res } = await this.$http.get(`/api/Semifinished/GetMaterial?gongdan=${row}`)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.Material = res.response
      this.Ifend()
    },
    Ifend () {
      var sum = 0
      if (this.Material.length === 0) {
        this.isendbool = false
        return
      }
      this.Material.forEach(element => {
        if (element.State === 1) {
          sum++
        }
      })
      if (sum === this.Material.length) {
        this.GetEndwuliao(this.$store.state.Gongdan)
      } else {
        this.isendbool = false
      }
    },
    async GetEndwuliao (row) {
      const { data: res } = await this.$http.get(`/api/Semifinished/GetEndwuliao?gongdan=${row}`)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.ERPMaterial = res.response
      this.isendbool = true
    },
    async DekERPwlandget () {
      this.$confirm(this.$t('All.请勿多次操作确认要重新获取ERP资料吗'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(async () => {
          const loadingInstance2 = this.$loading({ text: this.$t('All.获取数据中请稍等'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
          const { data: res } = await this.$http.post(`/api/Semifinished/DekERPwlandget?gongdan=${this.$store.state.Gongdan}`)
          if (res.status !== 200) {
            loadingInstance2.close()
            return this.$message.error(res.msg)
          }
          loadingInstance2.close()
          this.$message.success(res.msg)
        })
        .catch(() => {
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: fixed;
  height: 100%;
  background-color: rgba(255, 255, 255);
  min-width: 903px;
  width: 100%;
}
  .el-col {
    border: 1px solid;
    margin-right: -1px;
     margin-bottom: -1px;
    line-height: 4.5vw;
    font-size: 2vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .grid-content {
    min-height: 36px;
  }
  .bg-purple-light{

  }
  .row-bg {
    background-color: #f9fafc;
  }
table .td{
  border: none;
}
</style>
